<template>

 <!-- 学校管理页面 -->
  <div class="container">
    <div class="header-container">
      <div class="add-container">
        <el-cascader style="margin-right: 30px;" v-model="codeData" :props="props" @change="showGetData" clearable></el-cascader>
        <router-link :to="{name: 'managementSchoolAdd'}">
          <el-button class="addUser-button">
            新增
          </el-button>
        </router-link>
      </div>
    </div>
    <div class="body-container">
      <el-table
        :data="AdministratorFindSchoolListData"
        stripe
        style="width: 100%">
        <el-table-column
          type="selection"
          width="55">
        </el-table-column>
        <el-table-column
          prop="schoolName"
          label="学校名称">
        </el-table-column>
        <el-table-column
          prop="provinceName"
          label="省">
        </el-table-column>
        <el-table-column
          prop="cityName"
          label="市">
        </el-table-column>
        <el-table-column
          prop="areaName"
          label="区">
        </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <router-link :to="{name: 'managementSchoolEdit', params: {item: scope.row }}">
              <el-button
                size="mini"
                type="text"
                >编辑</el-button>
            </router-link>
            <el-divider direction="vertical"></el-divider>
             <router-link :to="{name: 'managementTeacher', query: {schoolId: scope.row.id }}">
              <el-button
                size="mini"
                type="text"
                >教师</el-button>
            </router-link>
            <el-divider direction="vertical"></el-divider>
             <router-link :to="{name: 'managementSchoolGrade', query: {schoolId: scope.row.id, schoolName: scope.row.schoolName }}">
              <el-button
                size="mini"
                type="text"
                >年级</el-button>
            </router-link>
            <el-divider direction="vertical"></el-divider>
            <el-button
              @click="showDel(scope.row.id)"
              size="mini"
              type="text"
              >删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="footer-container">
      <pagination :current-page="show.startPage" :total="show.totalPage"  @currentChange="editStartPage" @sizeChange="sizeChange"></pagination>
    </div>
  </div>
</template>
<script>
// import storage from '@/globals/storage/index.js'
import AdministratorService from '@/globals/service/management/Administrator.js'
import schoolService from '@/globals/service/detection/school.js'
import selectService from '@/globals/service/detection/select.js'

export default {
  data () {
    return {
      props: {
        lazy: true,
        checkStrictly: true,
        lazyLoad (node, resolve) {
          const { level } = node
          let data = []
          setTimeout(() => {
            if (level === 1) {
              AdministratorService.AdministratorUserCity({ provinceId: node.value }).then(res => {
                data = res.data.map(item => {
                  return {
                    value: item.citycode,
                    label: item.city,
                    leaf: level >= 2
                  }
                })
                resolve(data)
              })
              return
            }
            if (level === 2) {
              AdministratorService.AdministratorUserArea({ cityId: node.value }).then(res => {
                data = res.data.map(item => {
                  return {
                    value: item.areacode,
                    label: item.area,
                    leaf: level >= 2
                  }
                })
                resolve(data)
              })
              return
            }
            AdministratorService.AdministratorUserprovince().then(res => {
              data = res.data.map(item => {
                return {
                  value: item.provincecode,
                  label: item.province,
                  leaf: level >= 2
                }
              })
              resolve(data)
            })
            // 通过调用resolve将子节点数据返回，通知组件数据加载完成
          }, 1000)
        }
      },
      codeData: [],
      AdministratorFindSchoolListData: [],
      show: {
        startPage: 1,
        pageSize: 8,
        isGovernment: 0,
        totalPage: 1
      }
    }
  },
  created () {
    this.getData()
  },
  methods: {
    sizeChange (val) {
      this.show.pageSize = val
      this.getData()
    },
    showGetData () {
      if (this.codeData[0]) {
        this.show = {
          startPage: 1,
          pageSize: 8,
          isGovernment: 0,
          totalPage: 1
        }
      }
      this.getData()
    },
    del (id) {
      schoolService.del({ schoolIdList: [id] }).then(() => {
        this.$message({
          type: 'success',
          message: '删除成功!'
        })
        this.getData()
      })
    },
    editStartPage (index) {
      this.show.startPage = index
      this.getData()
    },
    showDel (id) {
      this.$confirm('此操作将永久删除该学校, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.del(id)
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        })
      })
    },
    getData () {
      const data = {}
      data.provinceId = this.codeData[0]
      data.cityId = this.codeData[1]
      data.areaId = this.codeData[2]
      if (!data.provinceId) {
        selectService.school({ startPage: this.show.startPage, pageSize: this.show.pageSize }).then(res => {
          this.AdministratorFindSchoolListData = res.data.schoolList
          this.show.totalPage = res.data.totalPage
        })
        return
      }
      AdministratorService.AdministratorFindSchoolList(data).then(res => {
        this.AdministratorFindSchoolListData = res.data
        // this.show.totalPage = res.data.totalPage
      })
    }
  }
}
</script>
<style lang="less" scoped>
.container{
  min-height: 100%;
  background-color: #fff;
  padding: 0 42px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .header-container{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 120px;
    .seek-container{
      display: flex;
      align-items: center;
      .seek-section{
        border: 1px solid #34BBEC;
        border-radius: 20px;
        display: flex;
        align-items: center;
        padding: 0 22px;
        color: #333;
        /deep/.el-input__inner{
          border: none !important;
          padding: 0 10px !important;
        }
        .el-icon-search{
          color: #999999;
        }
      }
      .seek-button{
        width: 80px;
        background-color: #34BBEC;
        margin-left: 20px;
        border-radius: 20px;
        color: #fff;
      }
    }
    .add-container{
      .addUser-button{
        background-color: #34BBEC;
        border-radius: 20px;
        color: #fff;
      }
    }
  }
  .body-container{
    flex: 1;
  }
  .footer-container{
    display: flex;
    width: 100%;
    padding: 33px 0;
    .el-button{
      margin-right: 160px;
    }
  }
}
</style>
